import React, { useState, useEffect } from "react";
import { doGET, doPUT } from "../../util/HttpUtil";
import PhoneNumWithValidation from "./PhoneNumWithValidation";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useContext } from "react";
import { DialogContext } from "../../store/context/DialogContext";
import { FaCheckCircle } from "react-icons/fa";
import OtpInput from "../../pages/Authentication/OtpInput";
import { ThreeBounce } from "better-react-spinkit";
import "./styles.css";


const btnStyle = {
  backgroundColor: '#1678AE',
  height: "40px",
  padding: "0 16px",
  fontSize: "16px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFF"
};

export default function PhoneWithOtpValidation({
  className,
  label = "Mobile Phone",
  value,
  onChange,
  isDisabled,
  formData,
  showErr,
  required,
  group = "Individuals_Lite",
  form_id,
  onError = () => { },
  type,
  onOtpVerify = () => { },
  placeholder = "Mobile Phone",
}) {

  const [otp, setOtp] = useState('');
  const [otpId, setOtpId] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);


  const toggleModal = () => setModalOpen(!modalOpen);

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const fullPhoneNumber = `${value.countryCode}${value.phone}`;
      const response = await doGET(`/api/message/send/otp?to=${fullPhoneNumber}&ref_id=${form_id}&platform=web`);
      if (response?.data?._id) {
        setOtpId(response.data._id);
        showMessage("OTP sent successfully!");
        setModalOpen(true);
        setOtpError("");

      } else {
        showError("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      showError(error?.message ?? "Error sending OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    if (otp.length !== 4) {
      setOtpError("Please enter a 4-digit OTP.");
      return;
    }
    setLoading(true);
    try {
      const response = await doPUT(
        `/api/individual-lite/update?otp_id=${otpId}&otpValue=${otp}`,
        { ...(formData ?? {}), mobilePhone: value?.phone, mobileCountryCode: value?.countryCode }
      );
      if (response.status === 200) {
        showMessage("OTP verified successfully!");
        onChange({
          ...value,
          phoneVerifiedViaOTP: true
        })
        onError({ errorMsg: "", hasError  : false});
        onOtpVerify()
        setModalOpen(false);
      } else {
        setOtpError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setOtpError(error?.message ?? "Error verifying OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    setOtpError(null);
  };

  const hasValue = () => {
    return value?.countryCode && value?.phone
  }

  return (
    <div className={`${className} fv_phone-otp-validation`}>
      <div className="fv_phone-validation-container">
        <div className="flex-1">
          <PhoneNumWithValidation
            label={label}
            value={value}
            onChange={(updatedValue) => {
              onChange({ ...updatedValue, phoneVerifiedViaOTP: false });
              setIsValid(false);
            }}
            isDisabled={isDisabled}
            notifyParent={(isValid) => setIsValid(isValid)}
            onError={(error) => {
              if (error?.hasError) setIsValid(false); else setIsValid(true);
              onError(error)
            }}
            showErr={showErr}
            required={required}
            err={hasValue() ? "" :  "Please enter a valid Mobile Phone"}
            group={group}
            form_id={form_id}
            placeholder={placeholder}
          />
        </div>

        {value?.phoneVerifiedViaOTP ? (
          <FaCheckCircle color="#28a745" size={20} title="OTP Verified" style={{ marginLeft: "10px", marginTop: '50px' }} />
        ) : (
          <Button
            style={btnStyle}
            onClick={handleSendOtp}
            disabled={loading || !value.phone || !value.countryCode || !isValid}
            className="fv_send-otp-button"
          >
            {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : "Send OTP"}
          </Button>
        )}
      </div>

      {/* OTP Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal} centered className="fv_modal-content">

        <ModalBody className="fv_modal-body pt-4">
          <p className="pb-3">Enter the OTP sent to {value.countryCode}-{value.phone}</p>
          <div className="fv_otp-input-container">
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              style={{
                borderRadius: "14px",
                border: "1px solid #C6F3FF",
                height: "50px",
                width: "50px",
                textAlign: "center",
                fontSize: "18px",
                margin: "0 5px"
              }}
            />
          </div>
          {otpError && <div className="fv_error-message">{otpError}</div>}
        </ModalBody>
        <ModalFooter className="fv_modal-footer">
          <Button
            style={btnStyle}
            onClick={handleOtpVerification}
            disabled={otp.length !== 4 || loading}
            className="fv_btn-primary"
          >
            {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : "Verify OTP"}
          </Button>
          <Button
            color="secondary"
            onClick={toggleModal}
            className="fv_btn-secondary"
            style={{
              backgroundColor: "#f1faff",
              color: "#007bff",
              border: "1px solid #c6f3ff",
              borderRadius: "10px",
              padding: "8px 16px",
              fontSize: "16px"
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
