import React from 'react'
import { QuestionRadioGroupMultiple } from './Page6'
import { Row } from 'reactstrap'
import { CountrySelect, InputField } from '../../../../../components'
import PercentageInput from './PercentageInput'
import YearPicker from '../../../../../components/DateSelects/YearPicker'
import moment from 'moment'

const Entity = ({ asLabel, data, validationkeyValue, handleChange, hasError, index, handleValidation }) => {
    return (
        <div>
            <QuestionRadioGroupMultiple
                field={`entities[${index}].relations`}
                question="Relationship with the applying entity."
                data={data}
                handleChange={handleChange}
                isDisabled={asLabel}
                style={{ fontSize: 15 }}
                error={handleValidation(`entities[].relations`, data?.entities?.[index].relations)}
                showErr={hasError}
                required
                options={validationkeyValue["entities[].relations"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
            />

            <Row>
                <InputField
                    className="col-sm-6"
                    label={"Name of the Entity"}
                    placeholder={"Name of the Entity"}
                    value={data?.entities?.[index]?.name}
                    onChange={(v) => handleChange(`entities[${index}].name`, v)}
                    error={handleValidation(`entities[].name`, data?.entities?.[index]?.name)}
                    showErr={hasError}
                    type="text"
                    required
                    isDisabled={asLabel}
                />
                <CountrySelect
                    label={"Country of Incorporation"}
                    placeholder={"Country of Incorporation"}
                    className="col-sm-6"
                    value={data?.entities?.[index]?.incorpCountry}
                    onChange={(v) => handleChange(`entities[${index}].incorpCountry`, v?.value)}
                    error={handleValidation('entities[].incorpCountry', data?.entities?.[index]?.incorpCountry)}
                    showErr={hasError}
                    required
                    isDisabled={asLabel}
                />

                <InputField
                    value={data?.entities?.[index]?.website}
                    label={"Website"}
                    placeholder="example.com"
                    onChange={(v) => handleChange(`entities[${index}].website`, v)}
                    type="text"
                    isDisabled={asLabel}
                    error={handleValidation('entities[].website', data?.entities?.[index]?.website)}
                    showErr={hasError}
                    className="col-sm-6"
                />

                <InputField
                    label={"Nature of the business"}
                    placeholder={"Nature of the business"}
                    value={data?.entities?.[index]?.businessNature}
                    onChange={(v) => handleChange(`entities[${index}].businessNature`, v)}
                    error={handleValidation(`entities[].businessNature`, data?.entities?.[index]?.businessNature)}
                    showErr={hasError}
                    type="text"
                    required
                    className="col-sm-6"
                    isDisabled={asLabel}
                />

                <YearPicker
                    value={data?.entities?.[index]?.yearOfIncorporation}
                    onChange={(v) => handleChange(`entities[${index}].yearOfIncorporation`, v)}
                    label="Year Of Incorporation"
                    placeholder="YYYY"
                    error={handleValidation(`entities[].yearOfIncorporation`, data?.entities?.[index]?.yearOfIncorporation)}
                    // minYear={1940}
                    maxYear={moment().year()}
                    required
                    format="yyyy"
                    isDisabled={asLabel}
                    showErr={hasError}
                    className="col-sm-6"
                />
            </Row>

            {data?.entities?.[index].relations === "shareholder" ? <Row className='px-1'>
                <PercentageInput
                    className="col-sm-6"
                    label={"Percentage of Ownership"}
                    value={data?.entities?.[index]?.percentOwner ? data?.entities?.[index]?.percentOwner : ""}
                    onChange={(e) => {
                        handleChange(`entities[${index}].percentOwner`, e);
                    }}
                    isDisabled={asLabel}
                    error={handleValidation(`entities[].percentOwner`, data?.entities?.[index]?.percentOwner)}
                    showErr={hasError}
                    required
                />
            </Row> : null}
        </div>

    )
}

export default Entity;