import React from 'react';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';

const Page3 = ({ asLabel, data, handleChange, hasError, handleValidation, validationkeyValue }) => {

    return (
        <div>
            <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>About your business</h2>

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.primaryBusinessActivity}
                onChange={(v) => { handleChange("primaryBusinessActivity", v) }}
                error={handleValidation("primaryBusinessActivity", data?.primaryBusinessActivity)}
                showErr={hasError}
                label="Describe in detail your company’s current primary business activities."
                className="mb-2 mt-3"
                isDisabled={asLabel}
                required={validationkeyValue["primaryBusinessActivity"]?.required}
            />

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                label="Describe your company’s current products and services."
                value={data?.Business_Product_Services}
                onChange={(v) => { handleChange("Business_Product_Services", v) }}
                error={handleValidation("Business_Product_Services", data?.Business_Product_Services)}
                showErr={hasError}
                type="text"
                className="my-2"
                isDisabled={asLabel}
                required={validationkeyValue["Business_Product_Services"]?.required}
            />

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.customers}
                onChange={(v) => { handleChange("customers", v) }}
                error={handleValidation("customers", data?.customers)}
                showErr={hasError}
                type="text"
                label="Describe who your customers are"
                className="mt-3"
                isDisabled={asLabel}
                required={validationkeyValue["customers"]?.required}
            />

            <QuestionRadioGroup
                style={{ fontSize: 15 }}
                field={"docPlan"}
                question="Do you have a documented business plan you can share?"
                data={data}
                handleChange={handleChange}
                hasError={hasError}
                isDisabled={asLabel}
                error={handleValidation("docPlan", data?.docPlan)}
                // showErr={hasError}
                showErr={hasError}
                required={validationkeyValue["docPlan"]?.required}
            />
            <CheckBoxFieldMutiple
                // className="my-3"
                labelStyle={{ fontSize: 15 }}
                label="Is your business involved in any of the following (check all that apply)"
                data={validationkeyValue["involvement"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                placeholder="Select"
                value={data.involvement?data.involvement:[]}
                onChange={(v) => handleChange('involvement', v)}
                error={handleValidation('involvement', data.involvement)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["involvement"]?.required}


            />

            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Is your business registered in any of these countries?"
                data={validationkeyValue["regCountries"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                placeholder="Select"
                value={data.regCountries?data.regCountries:[]}
                onChange={(v) => handleChange('regCountries', v)}
                error={handleValidation('regCountries', data.regCountries)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["regCountries"]?.required}

            />
    

            <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Expected Bank Account Activity</h2>
            <CheckBoxFieldMutiple
                className="my-1 col-sm-12 d-flex"
                labelStyle={{ fontSize: 15 }}
                label="Select all reasons you will keep receiving deposits into your account"
                data={validationkeyValue["depositReasons"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                placeholder="Select"
                value={data.depositReasons?data.depositReasons:[]}
                onChange={(v) => handleChange('depositReasons', v)}
                error={handleValidation('depositReasons', data.depositReasons)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["depositReasons"]?.required}
                desc={"Check All that apply"}

            />
    
            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Select all reasons you will be making payments from your account"
                data={validationkeyValue["payReason"]?.options?.map((v, index) => {return {...( v ?? {}), key : index}})}
                placeholder="Select"
                value={data.payReason?data.payReason:[]}
                onChange={(v) => handleChange('payReason', v)}
                error={handleValidation('payReason', data.payReason)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["payReason"]?.required}
                desc={"Check All that apply"}


            />
            
            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Locations you intend on receiving deposits from"
                data={validationkeyValue["depositLocation"]?.options}
                placeholder="Select"
                value={data.depositLocation ? data.depositLocation : []}
                onChange={(v) => handleChange('depositLocation', v)}
                error={handleValidation('depositLocation', data.depositLocation)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["depositLocation"]?.required}
                desc={"Check All that apply"}

            />
       
            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Locations you intend on making payments to"
                data={validationkeyValue["Payment_Locations"]?.options}
                placeholder="Select"
                value={data?.Payment_Locations ? data?.Payment_Locations : []}
                onChange={(v) => handleChange('Payment_Locations', v)}
                error={handleValidation('Payment_Locations', data.Payment_Locations)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Payment_Locations"]?.required}
                desc = {"Check All that apply"}
            />
            
        </div>
    )
}

export default Page3;