import React, { createContext, useState } from 'react';

// Translation JSON files
import hindi from '../locales/hindi.json';
import spanish from '../locales/spanish.json';
import french from '../locales/french.json';

// Default translations (English)
const defaultTranslations = {
  en: {},
  hi: hindi,
  es: spanish,
  fr: french
};

// Create Translation Context
const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [translations, setTranslations] = useState(defaultTranslations);
  const [missingTranslations, setMissingTranslations] = useState({}); // Track missing translations

  const t = (key) => {
    if(language == "en") return key;
    // Check if translation for the current language exists
    if (translations[language] && translations[language][key]) {
      return translations[language][key];
    }

    // If translation is missing, log to the console
    console.warn(`Missing translation for key: "${key}" in "${language}"`);

    // Update the translation object in memory (add missing translation as English by default)
    setTranslations((prevTranslations) => {
      const updatedTranslations = { ...prevTranslations };

      if (!updatedTranslations[language]) {
        updatedTranslations[language] = {};
      }

      // Add the missing key with the English value as default
      updatedTranslations[language][key] = key;

      return updatedTranslations;
    });

    // Track missing translations in the current language
    setMissingTranslations((prevMissing) => {
      const updatedMissing = { ...prevMissing };

      if (!updatedMissing[language]) {
        updatedMissing[language] = {};
      }

      // If the key was not already tracked as missing
      if (!updatedMissing[language][key]) {
        updatedMissing[language][key] = key;

        // Print the entire missing translations object when a new key is added
        console.log("Updated Missing Translations:", updatedMissing);
      }

      return updatedMissing;
    });

    // Return the key (English value) as fallback
    return key;
  };

  const changeLanguage = (newLanguage) => {
    if (translations[newLanguage]) {
      setLanguage(newLanguage);
    } else {
      console.warn(`Language "${newLanguage}" not available`);
    }
  };

  return (
    <TranslationContext.Provider value={{ t, changeLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = React.useContext(TranslationContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};
